<template>
  <div>
    <b-button class="mb-2" @click="$router.push('/empresas')"
      ><fa-icon icon="arrow-left"></fa-icon>Voltar</b-button
    >
    <Form />
  </div>
</template>

<script>
export default {
  components: {
    Form: () => import("./components/Form.vue"),
  },
};
</script>

<style>
</style>